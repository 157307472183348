import React, { Component } from "react";
import { ThreeWindow } from './ThreeWindow';
import { Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';
import Plot from 'react-plotly.js';

export default class PopUp extends Component {
    constructor(props) {
        super(props);

        this.toggleTab = this.toggleTab.bind(this);
        this.state = {
            activeTab: '3'
        };
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleClick = () => {
        this.props.toggle();
    };

    render() {
        return (
            <Modal isOpen={this.props.showDetailedWindow} toggle={this.handleClick} scrollable={true} size="xl" style={{ maxWidth: '1640px', width: '100%' }}>
                <ModalHeader toggle={this.handleClick}>Detailed Data:</ModalHeader>
                <ModalBody>
                    {this.props.showDetailedWindow ?
                        <div>
                            {(this.props.popupDetailedData != '{}') ?
                                <div>
                                    <Nav tabs>
                                        {(this.props.popupDetailedData["opticalimage"] != null && this.props.popupDetailedData["opticalimage"].length > 60) &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => { this.toggleTab('1'); }}
                                                >
                                                    Optical Image
                                                </NavLink>
                                            </NavItem>
                                        }
                                        {(this.props.popupDetailedData["flirimage"] != null && this.props.popupDetailedData["flirimage"].length > 60) &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => { this.toggleTab('2'); }}
                                                >
                                                    FLIR Image
                                                </NavLink>
                                            </NavItem>
                                        }
                                        {(this.props.popupDetailedData["points"] != null && this.props.popupDetailedData["points"].length > 0) &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '3' })}
                                                    onClick={() => { this.toggleTab('3'); }}
                                                >
                                                    Points 3D
                                                </NavLink>
                                            </NavItem>
                                        }
                                        {(this.props.popupDetailedData["holedata"] != null && this.props.popupDetailedData["holedata"] != "") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '4' })}
                                                    onClick={() => { this.toggleTab('4'); }}
                                                >
                                                    Hole Data
                                                </NavLink>
                                            </NavItem>
                                        }
                                    </Nav>
                                    {(this.state.activeTab == 1) &&
                                        <div>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <img src={this.props.popupDetailedData["opticalimage"]} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                    {(this.state.activeTab == 2) &&
                                        <div>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <img src={this.props.popupDetailedData["flirimage"]} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                    {(this.state.activeTab == 3) &&
                                        <div>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <div style={{ height: "60vh", width: "100%" }}><ThreeWindow threeWindowPointsData={this.props.popupDetailedData["points"]} /></div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                    {(this.state.activeTab == 4) &&
                                        <div>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <Plot
                                                            data={[
                                                                //First Graph
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["x1"],
                                                                    y: this.props.popupDetailedData["holedata"]["y1"],
                                                                    z: this.props.popupDetailedData["holedata"]["z1"],
                                                                    mode: 'lines',
                                                                    name: 'Down Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'red',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter3d'
                                                                },
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["x2"],
                                                                    y: this.props.popupDetailedData["holedata"]["y2"],
                                                                    z: this.props.popupDetailedData["holedata"]["z2"],
                                                                    mode: 'lines',
                                                                    name: 'Up Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'blue',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter3d'
                                                                }
                                                            ]}
                                                            layout={{
                                                                height: 600,
                                                                showlegend: true,
                                                                title: "3D View",
                                                                scene: {
                                                                    aspectmode: "data",
                                                                },
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <Plot
                                                            data={[
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["x1"],
                                                                    y: this.props.popupDetailedData["holedata"]["z1"],
                                                                    mode: 'lines',
                                                                    name: 'Down Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'red',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter'
                                                                },
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["x2"],
                                                                    y: this.props.popupDetailedData["holedata"]["z2"],
                                                                    mode: 'lines',
                                                                    name: 'Up Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'blue',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter'
                                                                }

                                                            ]}
                                                            layout={{
                                                                width: 800,
                                                                height: 600,
                                                                showlegend: true,
                                                                title: 'Side View 1 (X-Z)',
                                                                xaxis: {
                                                                    scaleanchor: "y",
                                                                }
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <Plot
                                                            data={[
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["y1"],
                                                                    y: this.props.popupDetailedData["holedata"]["z1"],
                                                                    mode: 'lines',
                                                                    name: 'Down Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'red',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter'
                                                                },
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["y2"],
                                                                    y: this.props.popupDetailedData["holedata"]["z2"],
                                                                    mode: 'lines',
                                                                    name: 'Up Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'blue',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter'
                                                                }

                                                            ]}
                                                            layout={{
                                                                width: 800,
                                                                height: 600,
                                                                showlegend: true,
                                                                title: 'Side View 2 (Y-Z)',
                                                                xaxis: {
                                                                    scaleanchor: "y",
                                                                }
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <Plot
                                                            data={[
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["x1"],
                                                                    y: this.props.popupDetailedData["holedata"]["y1"],
                                                                    mode: 'lines',
                                                                    name: 'Down Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'red',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter'
                                                                },
                                                                {
                                                                    x: this.props.popupDetailedData["holedata"]["x2"],
                                                                    y: this.props.popupDetailedData["holedata"]["y2"],
                                                                    mode: 'lines',
                                                                    name: 'Up Hole',
                                                                    opacity: 1,
                                                                    line: {
                                                                        width: 6,
                                                                        color: 'blue',
                                                                        reversescale: false
                                                                    },
                                                                    type: 'scatter'
                                                                }

                                                            ]}
                                                            layout={{
                                                                width: 800,
                                                                height: 600,
                                                                showlegend: true,
                                                                title: 'Top View (X-Y)',
                                                                yaxis: {
                                                                    scaleanchor: "x",
                                                                }
                                                            }}
                                                        />
                                                    </Col>

                                                </Row>
                                            </Container>
                                        </div>
                                    }
                                </div>
                                : <div>No Detailed Data</div>
                            }
                        </div>
                        : <div>Loading Data...</div>
                    }
                </ModalBody>
            </Modal>
        );
    }
}